@import "../styles";

.legal-page {
  @include secondary-header;

  .content {
    font-size: 0.9rem;
  }

  .section-paragraph {
    @include arial;
  }

  .section-title {
    @include jura;
    display: block;
    font-size: 2rem;
    margin: 50px 0 30px;
    color: #FFF;

    &:visited {
      color: #FFF;
    }

    &:hover {
      color: #CCC;
    }
  }
}