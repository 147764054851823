@import "../../styles";

.discord-content {
  padding: 30px 0;
  position: relative;
  background: #000;
  .join-the-conversation {
    @include hero-text;
  }

  .join-discord {
    @include cta;
    background: $hero-color;
    color: #FFF;
  }
  .teeth-top {
    @include teeth;
  }
  .teeth-bottom {
    @include teeth-inv;
  }
  .discord-image {
    float: right;
    height:100%;
    width: auto;
  }
}