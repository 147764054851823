@import "../styles";

.team-page {
  @include secondary-header;

  .emeritus {
    @include jura;
    text-align: center;
    margin: 40px auto;
    font-size: 2rem;
  }

  .teeth {
    padding: 50px 0;
    position: relative;
    background: #000;
    @include jura;
    p {
      text-align: center;
      margin: 0 auto;
      font-size: 1.5rem;
      .emphasis {
        font-weight: 800;
      }
    }
    //height: 90px;
    //position: relative;
    //background: #000;

    .teeth-top {
      @include teeth;
    }

    .teeth-bottom {
      @include teeth-inv;
    }
  }

  .team-card {
    margin-bottom: 50px;

    .team-name {
      @include rametto;
      margin-top: 15px;
      font-size: 1rem;
    }

    .team-title {
      @include rametto;
      font-size: 0.9rem;
      color: $hero-color;
      margin: 5px 0 15px;
    }

    .team-photo {
      display: block;
      max-height: 330px;
      width: auto;
      margin: 0 auto;
    }

    .team-text {
      @include josefin;
      height: 100px;
    }

    .team-link {
      @include jura;
      color: #FFF;

      &:visited {
        color: #FFF;
      }

      &:hover {
        color: #CCC;
      }
    }
  }
}