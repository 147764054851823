@import "../../styles";

.tagline {
  @include hero-text;
  margin: 0;
  position: relative;
  background: #000;
  padding: 20px 0;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25);
  text-align: center;

  .teeth-top {
    @include teeth;
  }

  .teeth-bottom {
    @include teeth-inv;
  }
}

.red {
  color: $hero-color;
}
