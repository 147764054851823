@import "../../styles";

.footer-section {
  margin-top: 75px;
  padding-top: 5rem;
  background: #000;

  .category {
    @include rametto;
  }

  .link {
    @include josefin;
    margin: 5px 0;
    display: block;
    color: #FFF;

    &:visited {
      color: #FFF;
    }

    &:hover {
      color: #CCC;
    }
  }

  .copyright {
    margin-top: 3rem;
    @include jura;
  }
  .credit {
    margin-top: 0.5rem;
    color: #888;
    font-size: 0.75rem;
    a {
      color: #ccc;
      &:visited {
        color: #ccc;
      }

      &:hover {
        color: #888;
      }
    }
  }
  .footer-logo {
    height: 70px;
    width: auto;
  }
}