@import "../../styles";

.headlice {
  margin-top: 100px;
  .headlice-wordmark {
    width: 60%;
    display: block;
    margin: -40px 0 0;
  }
  .headlice-screenshot {
    height: 100%;
    object-fit: cover;
  }
  .wishlist {
    @include cta;
    background: #FFF;
    color: $hero-color;
    margin-right: 20px;
  }
  .read-more {
    @include cta;
    background: $hero-color;
    color: #FFF;
  }
}