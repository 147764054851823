@import "../../styles";

.team-section {
  background: url('../../../images/concrete.png') no-repeat;

  .who-are-we {
    @include hero-text;
    margin: 80px 0 32px;
    @media (max-width: 950px) {
      font-size: 24px;
    }
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }

  .meet-the-team {
    @include cta;
    background: $hero-color;
    color: #FFF;
  }

  .team-image {
    float: right;
    height: 100%;
    width: auto;
  }
}
