@import "../../styles";

.videoContainer {
  position: relative;
  height: 500px;
  width: 100%;

  @media (min-width: 1280px) {
    height: 700px;
  }

  .bgVideo {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
