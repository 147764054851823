@import "../styles";

.game-page {
  @include secondary-header;
  &.alt {
    @include secondary-header-alt;
  }

  .game-wordmark {
    //height: 200px;
    //width: auto;
    display: block;
    margin: 0 auto;
  }

  .header-screenshot {
    display: block;
    margin: 0 auto;
  }

  .content {
    //@include josefin;
  }

  .game-tagline {
    @include hero-text;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
    position: relative;
    background: #000;
  }

  .game-footer {
    @include hero-text;
    font-size: 1.4rem;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
  }

  .wishlist {
    @include cta;
    background: #FFF;
    color: $hero-color;
    margin-right: 20px;
  }

  .read-more {
    @include cta;
    background: $hero-color;
    color: #FFF;
  }

  .screenshots {
    background: #000;
  }

}