@import "../../styles";

.news-content {
  padding: 30px 0;
  position: relative;

  .news-header {
    @include hero-text;
  }

  .image {
    width: 100%;
    background: #434343;

    .article-image {
      height: 220px;
      display: table-cell;
      vertical-align: middle;

      img {
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }
  }

  .article-text {
    @include border;
    width: 100%;
    height: 100px;
    padding: 5px 8px;
    background: #000;
  }

  .red {
    color: $hero-color;
  }
}