@import "../../styles";

.bg-logo {
  /* BG Logo (stacked) */

  position: absolute;
  width: 250px;
  height: auto;
  top: 125px;
  left: 50%;
  margin-left: -125px;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media (min-width: 1280px) {
    width: 400px;
    top: 175px;
    margin-left: -200px;
  }
}

.logo-dark {
  /* BG_Dark_Mode_2 1 */

  //position: absolute;
  height: auto;
  width: 100%;
  margin: 0 auto;

  filter: drop-shadow(0px 0px 40px $hero-color) drop-shadow(0px 0px 24px $hero-color) drop-shadow(0px 0px 12px $hero-color);
}