@import "../styles";

.games-page {
  @include secondary-header;

  .coldsalvage-wordmark {
    height: 80px;
    width: auto;
    display: block;
    margin: 0 0 15px;
  }

  .headlice-wordmark {
    height: 135px;
    width: auto;
    display: block;
    margin: -40px 0 0;
    padding: 20px 0;
  }

  .content {
    @include josefin;
  }

  .wishlist {
    @include cta;
    background: #FFF;
    color: $hero-color;
    margin-right: 20px;
  }

  .read-more {
    @include cta;
    background: $hero-color;
    color: #FFF;
  }

  .game-description {
    height: 270px;
  }

  .game-links {
    height: 120px;
  }
}