@import "../../styles";

.coldsalvage {
  margin-top: 100px;
  .coldsalvage-wordmark {
    width: 90%;
    display: block;
    margin: 0 0 15px;
  }
  .coldsalvage-screenshot {
    height: 100%;
    object-fit: cover;
  }
  .wishlist {
    @include cta;
    background: #FFF;
    color: $hero-color;
    margin-right: 20px;
  }
  .read-more {
    @include cta;
    background: $hero-color;
    color: #FFF;
  }
}