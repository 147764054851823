@import "styles";

$navbar-item-img-max-height: 3.5rem;
$navbar-height: 7rem;
$card-background-color: #000;
$card-color: #FFF;
@import "bulma/bulma.sass";

html {
  height: 100%;
}

body {
  @include arial;
  background: #202020;
  color: #FFF;
  font-size: 1.2rem;
  min-height: 100%;
}

a {
  color: #FFF;

  &:visited {
    color: #FFF;
  }

  &:hover {
    color: #CCC;
  }
}

@media (max-width: 1023px) {
  .container {
    padding: 0 30px;
  }
  .navbar-burger {
    color: #FFF;
  }
}

