.discord {
  /* Discord_Unselected */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 13px 20px;
  gap: 16px;

  //width: 218px;
  //height: 47.56px;

  border: 1px solid #FFFFFF;
  border-radius: 10px;
}

.discord-logo {
  /* Vector */

  width: 30px;
  height: 21.56px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.discord-text {
  /* JOIN DISCORD */

  line-height: 40px;
  width: 132px;
  height: 18px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height */

  color: #FFFFFF;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}