@import "../../styles";

.shop-content {
  padding: 30px 0;
  position: relative;
  .buy-merchandise {
    @include hero-text;
  }
.visit-shop {
  @include cta;
  background: $hero-color;
  color: #FFF;
}
}