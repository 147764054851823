@import "../styles";

.presskit-page {
  @include secondary-header;


  .presskit-content {
    padding: 30px 0;
    position: relative;
    background: #000;

    .presskit-header {
      @include hero-text;
    }

    img.brand-image {
      max-height: 100px;
      width: auto;
      display: inline-block;
      margin: 3px 5px;
      &.inv {
        background-color: #FFF;
      }
    }

    a {
      text-decoration: underline;
    }

    ul {
      margin-bottom: 1em;

      li {
        display: list-item;
        list-style: outside;
        margin-left: 1em;
      }
    }

    .subtitle {
      color: #CCC;
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 30px;
    }
  }
}