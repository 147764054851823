@import "../styles";

.contact-page {
  @include secondary-header;

  .teeth {
    padding: 50px 0;
    position: relative;
    background: #000;
    @include jura;
    p {
      text-align: center;
      margin: 0 auto;
      font-size: 1.5rem;
      .emphasis {
        font-weight: 800;
      }
    }

    .teeth-top {
      @include teeth;
    }

    .teeth-bottom {
      @include teeth-inv;
    }
  }
}