.navbar {
  &.is-fixed {
    position: absolute;
  }
  background: #000;
  width: 100%;
  padding: 0 1.7rem;
  .navbar-item {
    margin: 0px 0.4rem;
    font-size: 1.2rem;
    color: #FFF;
    text-transform: uppercase;
    font-family: 'Jura', sans-serif;
    font-weight: 400;

    &.selected {
      text-shadow: -18px 0px 20px #ff0000,
      18px 0px 20px #ff0000,
      0px 0px 20px #ff0000,
      -18px -8px 20px #ff0000,
      8px -8px 20px #ff0000,
      0px -8px 20px #ff0000,
      -18px 8px 20px #ff0000,
      18px 8px 20px #ff0000,
      0px 8px 20px #ff0000;
    }

    &:hover,&:focus {
      color: #FFF;

      background: none;
      text-shadow: -18px 0px 20px #F44,
      18px 0px 20px #F44,
      0px 0px 20px #F44,
      -18px -8px 20px #F44,
      8px -8px 20px #F44,
      0px -8px 20px #F44,
      -18px 8px 20px #F44,
      18px 8px 20px #F44,
      0px 8px 20px #F44;
    }
  }
  .navbar-brand {
    .logo {
      display: block;
      height: 56px;
      width: 195px;
    }
  }
  .navbar-start.navbar-center {
    flex-grow: 1;
    justify-content: center;
  }
  .navbar-end {
    .social-icon {
      padding: 5px;
      border: 1px solid #FFF;
      border-radius: 2px 5px;
      .icon {
        height: 40px;
      }
    }
  }
  @media (max-width: 1023px) {
    .navbar-menu {
      background: #000;
    }
  }
}